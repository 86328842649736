import { Typography, Button, Paper, IconButton } from '@mui/material'
import React from 'react'
import './styles.css'
import Investment from '../../assets/Investments/Ship Investment.jpg'
import CloseIcon from '@mui/icons-material/Close'

const InvestmentMore = ({ handleCloseMoreInfo }) => {
  return (
    <>
        <div className='overlay'>

        </div>
        <div className="more-container">
            
            <Paper className='more-paper'>
                <IconButton onClick={handleCloseMoreInfo} sx={{position:'absolute', top:0, right: 0, zIndex: 5,}} className='more-close'>
                    <CloseIcon />
                </IconButton>
                <div className="more-content">
                    <img src={Investment} alt="" className='invest-more-img' />
                    <div className="more-info">
                        <Typography>Coming Soon</Typography>
                        <Button  variant='contained'>Begin Investing</Button>
                    </div>
                </div>
            </Paper>
        </div>
    </>
  )
}

export default InvestmentMore